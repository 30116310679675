'use client';

import { ProductCardDiscountChip } from './ProductCardDiscountChip';
import { ProductPrice } from './ProductPrice';
import { Sale } from './types';
import { useSaleState } from './useIsActiveSale';

type ProductCardPriceSectionProps = {
  price: {
    currentPrice: number;
    originalPrice: number;
    currency?: string;
    discountPercent?: number;
  };
  sale?: Sale;
};

export const ProductCardPriceSection = ({
  price,
  sale,
}: ProductCardPriceSectionProps) => {
  const { active } = useSaleState(sale);

  return (
    <div
      data-test="search-results-price"
      className="mt-2 flex items-start justify-between lg:mt-3 lg:items-center"
    >
      <ProductPrice
        isSaleActive={active}
        price={price}
        className="lg:body body-small"
      />
      {active && sale && (
        <ProductCardDiscountChip percent={price.discountPercent ?? 0} />
      )}
    </div>
  );
};
