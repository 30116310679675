'use client';

import { PropsWithChildren, createContext, useContext } from 'react';
import { MicrocopyBySet } from './types';

const MicrocopyBySetContext = createContext<MicrocopyBySet>({});

export const MicrocopyBySetProvider = ({
  children,
  data,
}: PropsWithChildren<{ data: MicrocopyBySet }>) => {
  return (
    <MicrocopyBySetContext.Provider value={data}>
      {children}
    </MicrocopyBySetContext.Provider>
  );
};

export const useMicrocopy = (
  set: string,
  defaultValues: Record<string, string>
) => {
  const microcopy = useContext(MicrocopyBySetContext);

  return {
    ...defaultValues,
    ...(microcopy[set] || {}),
  } as Record<string, string>;
};
