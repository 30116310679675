'use client';
import { useCountdown } from '@lib/hooks/useCountdown';
import { isPast } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

type UseHeroCountdownResult = {
  hasCountdown: boolean;
  expired: boolean;
  countdown?: {
    days: number | undefined;
    hours: number;
    minutes: number;
    seconds: number;
  };
};

export const useHeroCountdown = (
  target: Date | null,
  onExpire: () => void
): UseHeroCountdownResult => {
  const targetIsInPast = target ? isPast(target) : false;
  const hasCountdown = target !== null;
  const [isExpired, setIsExpired] = useState(targetIsInPast);

  const countdownDuration = useCountdown(target ?? undefined);

  /**
   * We want to make sure that we always render something
   * for hours, minutes and seconds - even if the values
   * of these keys in the Duration are undefined
   * which happens to `hours` for example
   * when the duration is < 60 minutes
   */
  const paddedDuration = useMemo(() => {
    return {
      days: countdownDuration?.days,
      hours: countdownDuration?.hours || 0,
      minutes: countdownDuration?.minutes || 0,
      seconds: countdownDuration?.seconds || 0,
    };
  }, [countdownDuration]);

  const isNegativeDuration = useMemo(() => {
    if (paddedDuration) {
      return (
        (paddedDuration?.days && paddedDuration?.days < 0) ||
        paddedDuration?.hours < 0 ||
        paddedDuration?.minutes < 0 ||
        paddedDuration?.seconds < 0
      );
    }

    return false;
  }, [paddedDuration]);

  useEffect(() => {
    if (isNegativeDuration && !isExpired) {
      setIsExpired(true);
      onExpire();
    }
  }, [isNegativeDuration, isExpired, onExpire]);

  if (target === null) {
    return {
      hasCountdown: false,
      expired: false,
    };
  }

  return {
    hasCountdown,
    expired: isExpired,
    countdown: paddedDuration,
  };
};
