'use client';
import { RefObject, useEffect, useMemo, useState } from 'react';
import { useIsClient } from './useIsClient';

/**
 * Used to measure if the element passed in `ref` is visible on the screen
 * @param ref ulement that visibility should be measured for
 * @param threshold how much of the element needs to be visible for the return boolean to switch, defined as a float. For example: 50% = 0.5;
 */
export const useElementOnScreen = (
  ref: RefObject<HTMLElement>,
  threshold: number
) => {
  const isClient = useIsClient();
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(() => {
    if (!isClient) {
      return;
    }

    return new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        threshold,
      }
    );
  }, [isClient]);

  useEffect(() => {
    if (ref.current) {
      observer?.observe(ref.current);
    }
    return () => observer?.disconnect();
  }, [ref, observer]);

  return isIntersecting;
};
