export enum SaleType {
  Sale = 'on_sale',
  FlashDealTimeBased = 'flash_deal_time_based',
  NewReleaseDiscount = 'new_release_discount',
}

export type Sale = {
  dates: {
    starts: string;
    ends: string;
  };
  prices: {
    USD: string;
    EUR: string;
  };
  discount: {
    type: string;
    value: string;
  };
  type?: SaleType;
};
