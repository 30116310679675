'use client';
import { useElementOnScreen } from '@lib/hooks/useElementOnScreen';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';

type TrackPromotionImpressionProps = {
  shouldTrack: boolean;
  onImpression: () => void;
};
export const TrackPromotionImpression = ({
  shouldTrack,
  onImpression,
  children,
}: PropsWithChildren<TrackPromotionImpressionProps>) => {
  const [hasFired, setHasFired] = useState(false);
  const elementRef = useRef(null);
  const onScreen = useElementOnScreen(elementRef, 1);

  useEffect(() => {
    if (onScreen && !hasFired) {
      onImpression();
      setHasFired(true);
    }
  }, [onScreen, hasFired]);

  if (!shouldTrack) {
    return children;
  }

  return <div ref={elementRef}>{children}</div>;
};
