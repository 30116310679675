export function longTextToProductIds(productIds: string): string[] {
  return (
    productIds
      ?.trim()
      .split(',')
      /**
       * The comma-separated IDs are defined in a LongText-field
       * they can include spaces and newlines, so we need to trim each item
       */
      .map((id) => id.trim())
      .filter((id) => !isNaN(Number(id))) || []
  );
}
