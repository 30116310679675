'use client';

import { useFlags } from '@lib/flags/amplitude/client';
import { FEATURE_FLAG_ON, Flags } from '@lib/flags/flagConstants';
import { failingFetcher } from '@lib/http/fetch';
import { Entitlement } from '@lib/types/api/Entitlement';
import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import useSWR from 'swr';

export const EntitlementsContext = createContext<{
  entitlements?: Entitlement[];
  getUserEntitlement: (productId: string) => Entitlement | undefined;
  errorFetchingEntitlements: any;
}>({
  entitlements: [],
  getUserEntitlement: () => undefined,
  errorFetchingEntitlements: null,
});

export const EntitlementsProvider = ({ children }: PropsWithChildren) => {
  const { flags } = useFlags();
  const isPurchasedStateOn =
    flags?.[Flags.SHOW_PURCHASED_STATE]?.value === FEATURE_FLAG_ON;

  const { data, error } = useSWR<{ entitlements: Entitlement[] }>(
    isPurchasedStateOn ? `/api/users/entitlements` : null,
    failingFetcher
  );

  const value = useMemo(() => {
    const entitlements = data?.entitlements;
    return {
      entitlements,
      getUserEntitlement: (productId: string) => {
        return entitlements?.find(
          (entitlement) => entitlement.productId === productId
        );
      },
      errorFetchingEntitlements: error,
    };
  }, [data, error]);

  return (
    <EntitlementsContext.Provider value={value}>
      {children}
    </EntitlementsContext.Provider>
  );
};

export const useEntitlements = () => {
  return useContext(EntitlementsContext);
};
