import c from 'classnames';
import { ReactNode } from 'react';

type ProductRowProps = {
  children: ReactNode;
  gridCols?: number;
};

export const ProductRow = ({ children, gridCols = 4 }: ProductRowProps) => {
  if (!children) {
    return null;
  }

  return (
    <div className="container relative">
      <div
        className={c(
          `hide-scrollbar flex gap-4 overflow-x-scroll md:grid md:overflow-visible lg:gap-5 lg:gap-y-2`,
          gridCols === 5 ? 'grid-cols-4 lg:grid-cols-5' : 'grid-cols-4'
        )}
      >
        {children}
      </div>
    </div>
  );
};
