import c from 'classnames';

/**
 * Calculates the CSS classes for product cards on tablet devices to hide excess products.
 * This prevents half-filled rows by hiding products that would not fill an entire row.
 * Also used in the DynamicProductListingCards component.
 *
 * @param {number} index - The index of the product card in the list.
 * @param {number} numProducts - The total number of product cards.
 * @returns {string} - A string of class names to be applied to the product card.
 */
export const getProductCardTabletClasses = (
  index: number,
  numProducts: number
) => {
  const COLUMNS_TABLET = 4;
  const hiddenIndexThreshold = numProducts - (numProducts % COLUMNS_TABLET);

  return c('mr-0 min-w-[242px] md:min-w-full', {
    'md:hidden lg:block': index >= hiddenIndexThreshold,
  });
};
