'use client';

import { TrackPromotionImpression } from '@components/TrackPromotionImpression/TrackPromotionImpression';
import { usePromoTrackingEvents } from '@components/TrackPromotionImpression/usePromoTrackingEvents';
import { HeroFragment } from '@graphql/generated-contentful/graphql';
import { toNonNullable } from '@lib/util/toNonNullable';
import { Hero as HeroUI } from 'ui-components';

type HeroProps = {
  data: HeroFragment;
  position: number;
};

const Hero = ({ data, position }: HeroProps) => {
  const {
    heading,
    description,
    image,
    slim,
    imageFocusArea,
    titleInternal,
    trackAsPromo,
  } = data || {};

  const imageUrl = image?.url;
  const imageDescription = image?.description ?? '';

  const { promoViewTrackingEvent } = usePromoTrackingEvents({
    title: titleInternal || '',
    typename: 'Hero',
    position,
    creativeTitle: toNonNullable(image?.title),
    trackAsPromo: trackAsPromo ?? false,
  });

  if (!heading || !imageUrl) {
    return null;
  }

  return (
    <TrackPromotionImpression
      onImpression={promoViewTrackingEvent}
      shouldTrack={trackAsPromo ?? false}
    >
      <HeroUI
        title={heading}
        image={{
          url: imageUrl,
          alt: imageDescription,
          height: toNonNullable(image.height),
          width: toNonNullable(image.width),
        }}
        imageFocusArea={imageFocusArea}
        description={description}
        slim={slim ?? false}
      />
    </TrackPromotionImpression>
  );
};

export default Hero;
