'use client';

import { TrackPromotionImpression } from '@components/TrackPromotionImpression/TrackPromotionImpression';
import { usePromoTrackingEvents } from '@components/TrackPromotionImpression/usePromoTrackingEvents';
import { CalloutSlimFragment } from '@graphql/generated-contentful/graphql';
import { toNonNullable } from '@lib/util/toNonNullable';
import { ButtonStyle, CalloutSlim as CalloutSlimUI } from 'ui-components';

type CalloutSlimProps = {
  data: CalloutSlimFragment;
  position: number;
};

const CalloutSlim = ({ data, position }: CalloutSlimProps) => {
  const {
    subheading,
    heading,
    description,
    image,
    cta,
    legalDisclaimer,
    imageFocusArea,
    title,
    trackAsPromo,
  } = data || {};
  const imageUrl = image?.url;
  const imageDescription = image?.description;

  const { promoClickTrackingEvent, promoViewTrackingEvent } =
    usePromoTrackingEvents({
      title: title || '',
      typename: 'CalloutSlim',
      position,
      creativeTitle: toNonNullable(image?.title),
      trackAsPromo: trackAsPromo ?? false,
      ctas: cta?.value ? [cta.value] : [],
    });

  if (!heading || !imageUrl) {
    return null;
  }

  return (
    <TrackPromotionImpression
      onImpression={promoViewTrackingEvent}
      shouldTrack={trackAsPromo ?? false}
    >
      <CalloutSlimUI
        title={heading}
        subtitle={subheading}
        image={{
          url: imageUrl,
          alt: imageDescription ?? '',
          height: toNonNullable(image?.height),
          width: toNonNullable(image?.width),
        }}
        imageFocusArea={imageFocusArea}
        description={description}
        action={{
          label: cta?.value!,
          url: cta?.url!,
          openInNewTab: cta?.openInNewTab ?? false,
          style:
            cta?.appearance === 'primary'
              ? ('primary' as ButtonStyle)
              : ('secondary' as ButtonStyle),
        }}
        legalDisclaimer={legalDisclaimer}
        onCtaClick={promoClickTrackingEvent}
      />
    </TrackPromotionImpression>
  );
};

export default CalloutSlim;
