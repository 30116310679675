'use client';
import { TrackPromotionImpression } from '@components/TrackPromotionImpression/TrackPromotionImpression';
import { usePromoTrackingEvents } from '@components/TrackPromotionImpression/usePromoTrackingEvents';
import { ContentCardsFragment } from '@graphql/generated-contentful/graphql';
import { isDefined } from '@lib/util/isDefined';
import { toNonNullable } from '@lib/util/toNonNullable';
import c from 'classnames';
import { ButtonStyle, ContentCard, ContentCardProps } from 'ui-components';
import { BlocksTitle } from '../shared/BlocksTitle';

type ContentCardsProps = {
  data: ContentCardsFragment;
  position: number;
  isDarkTheme?: boolean;
};

const contentCardsGridCols = {
  3: 'grid-cols-1 md:grid-cols-3 lg:grid-cols-3',
  4: 'grid-cols-1 md:grid-cols-2 lg:grid-cols-4',
};

const ContentCards = ({
  data,
  position,
  isDarkTheme = false,
}: ContentCardsProps) => {
  const { promoClickTrackingEvent, promoViewTrackingEvent } =
    usePromoTrackingEvents({
      title: data.titleInternal || '',
      typename: 'ContentCards',
      position,
      trackAsPromo: data.trackAsPromo ?? false,
      ctas:
        data?.cardsCollection?.items
          .map((card) => card?.cta?.value)
          .filter(isDefined) || [],
    });

  if (!isDefined(data) || !isDefined(data.cardsCollection)) {
    return null;
  }

  const renderableContentCards: ContentCardProps[] = data.cardsCollection.items
    .map((card, index) => {
      if (card) {
        return {
          title: toNonNullable(card.header),
          id: `content-card-${card.sys.id}-${index}`,
          description: card.description!,
          image:
            card.image && card.image.url
              ? {
                  url: card.image.url,
                  alt: toNonNullable(card.image.description),
                }
              : undefined,
          cta:
            card.cta && card.cta.value && card.cta.url
              ? {
                  label: card.cta.value,
                  url: card.cta.url,
                }
              : undefined,
          ctaStyle: data.ctaAppearance as ButtonStyle,
          label: toNonNullable(card.label),
          onCtaClick: () => promoClickTrackingEvent(card.cta?.value || ''),
        };
      }
      return null;
    })
    .filter(isDefined);

  const cardCount = renderableContentCards.length;

  return (
    <TrackPromotionImpression
      onImpression={promoViewTrackingEvent}
      shouldTrack={data.trackAsPromo ?? false}
    >
      <div className="container">
        {(data.contentCardsTitle ||
          data.contentCardsDescription ||
          data.text) && (
          <div className="mb-8">
            <BlocksTitle
              title={data.contentCardsTitle}
              description={data.contentCardsDescription}
              text={data.text}
              isDarkTheme={isDarkTheme}
            />
          </div>
        )}
        <ul
          className={c(
            'grid grid-flow-dense gap-4 xl:gap-5',
            contentCardsGridCols[cardCount]
          )}
          data-test="content-cards-wrapper"
        >
          {renderableContentCards.map((card) => (
            <li key={card.id} data-test="content-card">
              <ContentCard {...card} isDarkTheme={isDarkTheme} />
            </li>
          ))}
        </ul>
      </div>
    </TrackPromotionImpression>
  );
};

export default ContentCards;
