import { ProductRow as ProductRowUI } from '@components/Product/ProductRow';
import { getProductCardTabletClasses } from '@components/Product/productCardTabletStyles';
import { HeadlessProductCardWrapper } from '@components/Search/HeadlessProductCardWrapper';
import { useResults } from '@components/Search/hooks/useResults';
import { useProductTags } from '@contexts/ProductTagsContext';
import { AtomicListingContext } from '@contexts/coveo/AtomicListingContext';
import { mapContentfulTags } from '@lib/util/tags';
import { useContext } from 'react';

export const DynamicProductListingCards = ({
  gridCols,
}: {
  gridCols: number;
}) => {
  const { searchEngine } = useContext(AtomicListingContext);
  const { results: resultState } = useResults(searchEngine);
  const productTags = useProductTags();
  const mappedProductTags = productTags ? mapContentfulTags(productTags) : [];

  if (!resultState?.results || resultState?.results.length === 0) {
    return null;
  }

  return (
    <ProductRowUI gridCols={gridCols}>
      {resultState?.results.map((result, index) => (
        <HeadlessProductCardWrapper
          key={result.raw.ec_product_id as string}
          context={AtomicListingContext}
          result={result}
          allProductTags={mappedProductTags}
          className={getProductCardTabletClasses(
            index,
            resultState.results.length
          )}
        />
      ))}
    </ProductRowUI>
  );
};
