'use client';

import {
  toPromoClickTrackingEventProperties,
  toPromoViewTrackingEventProperties,
} from '@lib/analytics/formatAnalyticsProperties';
import { analytics } from '@lib/analytics/page-events';

export const usePromoTrackingEvents = ({
  title,
  typename,
  position,
  creativeTitle,
  ctas,
  trackAsPromo,
}: {
  title: string;
  typename: string;
  position: number;
  creativeTitle?: string;
  trackAsPromo: boolean;
  ctas?: string[];
}) => {
  const promoViewTrackingEvent = () => {
    analytics.pushPromoViewEvent(
      toPromoViewTrackingEventProperties({
        title,
        typename,
        position,
        creativeTitle,
        ctas,
      })
    );
  };

  const promoClickTrackingEvent = (ctaLabel: string) => {
    if (trackAsPromo) {
      analytics.pushPromoClickEvent(
        toPromoClickTrackingEventProperties({
          title,
          typename,
          position,
          creativeTitle,
          ctaLabel,
        })
      );
    }
  };

  return { promoViewTrackingEvent, promoClickTrackingEvent };
};
