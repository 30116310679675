'use client';
import { EntitlementsProvider } from '@contexts/EntitlementsContext';
import ProductRow, { ProductRowProps } from './ProductRow';

/**
 * Wrapper component used so that ProductRow can have access to entitlements information
 */
const ProductRowWrapper = ({ data, position }: ProductRowProps) => {
  return (
    <EntitlementsProvider>
      <ProductRow data={data} position={position} />
    </EntitlementsProvider>
  );
};

export default ProductRowWrapper;
