'use client';

import { SearchEngine, buildResultList } from '@coveo/atomic-react';
import { useEffect, useState } from 'react';

export const useResults = (searchEngine: SearchEngine<{}> | undefined) => {
  const [resultListController] = useState(
    searchEngine && buildResultList(searchEngine)
  );
  const [resultListState, setResultListState] = useState(
    resultListController?.state
  );

  /* Subscribe to Headless result list controller so state updates when changes happen */
  useEffect(
    () =>
      resultListController?.subscribe(() =>
        setResultListState(resultListController.state)
      ),
    [resultListController]
  );

  return {
    results: resultListState,
  };
};
