import { TagFragment } from '@graphql/generated-contentful/graphql';
import { Tag } from '@lib/types/common/Tag';
import { isDefined } from './isDefined';
import { toNonNullable } from './toNonNullable';

/**
 * Converts from Contentful Tag to our Tag type.
 */
export const mapContentfulTags = (tags: TagFragment[]): Tag[] => {
  return tags
    .map((tag) => {
      if (!tag.label || !tag.slug) {
        return null;
      }
      return {
        label: tag.label,
        slug: tag.slug,
        iconName: toNonNullable(tag.iconName),
        priority: tag.priority ?? 1,
      };
    })
    .filter(isDefined);
};

/**
 * Returns tag translations and icon mapping for a given list of product slugs.
 * Also sorts the list of taqs by priority.
 */
export const getTagsDetailsBySlugs = (
  productTags: string[],
  allTags: Tag[]
): Tag[] => {
  return allTags
    .filter((tag) => productTags.includes(tag.slug))
    .sort((a, b) => a.priority - b.priority);
};
