'use client';

import { failingFetcher } from '@lib/http/fetch';

export const submitEmailSignupForm = async (email: string, country: string) => {
  // after landing pages are migrated to app router, call to marketplaceClient from /api/users/newsletter can just be executed here without the extra API route
  return failingFetcher(
    `/api/users/newsletter?email=${email}&country=${country}`,
    {
      method: 'POST',
    }
  );
};
