/**
 * Validates that the email is a proper email format.
 * Regex taken from the official HTML5 spec to match what an input type=email would do.
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#validation
 */
export const validateEmailFormat = (email: string) => {
  return email
    .toLowerCase()
    .match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    );
};
