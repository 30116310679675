import { ProductCardProps } from '@components/Product/ProductCard';
import { ProductTagsType } from '@contexts/ProductTagsContext';
import { analytics } from '@lib/analytics/page-events';
import {
  getActiveSale,
  getDiscountPercent,
  getSalePrice,
} from '@lib/coveo/sale';
import { SaleType } from '@lib/types/Sale';
import { Entitlement } from '@lib/types/api/Entitlement';
import { GetAssetByIdResponse } from '@lib/types/api/next-api/responses';
import { isDefined } from '@lib/util/isDefined';
import { getTagsDetailsBySlugs, mapContentfulTags } from '@lib/util/tags';

// Function to map usePackagesByIds data to ProductCardProps
export const mapToProductCardProps = (
  data: GetAssetByIdResponse[],
  productTags: ProductTagsType,
  getUserEntitlement: (productId: string) => Entitlement | undefined
) => {
  return data
    .map((product: GetAssetByIdResponse): ProductCardProps | null => {
      const sale = product.sales ? getActiveSale(product.sales) : undefined;
      const productPrice = sale
        ? getSalePrice(sale, product.price.currency)
        : Number(product.price.originalPrice);

      const mappedProductTags = productTags
        ? mapContentfulTags(productTags)
        : [];

      const tag = product.tags
        ? getTagsDetailsBySlugs(product.tags, mappedProductTags)[0]
        : null;

      return {
        productId: product.id,
        name: product.name,
        imageUrl: product.thumbnail,
        publisher: product.publisher
          ? {
              name: product.publisher.name,
              id: product.publisher.publisherId,
            }
          : undefined,
        rating: product.rating.average,
        ratingCount: product.rating.count,
        price: {
          currentPrice: productPrice,
          originalPrice: Number(product.price.originalPrice),
          currency: product.price.currency,
          discountPercent: sale?.discount.value
            ? getDiscountPercent(sale)
            : undefined,
        },
        entitlement: getUserEntitlement(product.id),
        sale: sale
          ? {
              type: sale.type ?? SaleType.Sale,
              dates: {
                starts: new Date(sale.dates.starts),
                ends: new Date(sale.dates.ends),
              },
            }
          : undefined,
        label: tag
          ? {
              text: tag.label,
              iconName: tag.iconName,
            }
          : undefined,
        extraEventHandlers: {
          onClick: () => {
            analytics.pushProductClickEvent(
              {
                product_id: product.id,
                product_name: product.name,
                product_price: productPrice,
                product_publisher: product.publisher?.name ?? '',
                product_publisher_id: product.publisher?.publisherId ?? '',
                product_discount_percentage: sale
                  ? product.price.discountPercent
                  : 0,
                product_discount: sale
                  ? Number(product.price.originalPrice) -
                    Number(product.price.currentPrice)
                  : 0,
                product_is_on_sale: Boolean(sale),
                product_is_free: productPrice === 0,
              },
              product.price.currency
            );
          },
        },
      };
    })
    .filter(isDefined);
};
