import { Sale, SaleType } from '@lib/types/Sale';
import * as Sentry from '@sentry/nextjs';
import { isWithinInterval, parseISO } from 'date-fns';

/**
 * Returns an active sale with the highest discount value
 */
export function getActiveSale(
  sales: Sale[],
  flashDealTeaserTime?: string
): Sale | null {
  if (!sales) {
    return null;
  }
  try {
    const now = new Date();

    const activeSales = sales.filter((sale) => {
      if (sale.type === SaleType.FlashDealTimeBased && flashDealTeaserTime) {
        return isWithinInterval(now, {
          start: new Date(flashDealTeaserTime),
          end: parseISO(sale.dates.ends),
        });
      }

      return isWithinInterval(now, {
        start: parseISO(sale.dates.starts),
        end: parseISO(sale.dates.ends),
      });
    });

    if (!activeSales.length) {
      return null;
    }

    const compareSalesByDiscount = (a, b) => {
      const discountA = parseFloat(a.discount.value);
      const discountB = parseFloat(b.discount.value);

      return discountB - discountA;
    };

    const sortedActiveSales = [...activeSales].sort(compareSalesByDiscount);

    // compass-services returns a sale without the type and one with the type for flash deals, so should filter out the Flash deal one if it exists
    return (
      sortedActiveSales.find(
        (sale) => sale.type === SaleType.FlashDealTimeBased
      ) ?? sortedActiveSales[0]
    );
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
}

export function getSalePrice(sale: Sale, currency: string): number {
  return sale.prices[currency];
}

export function getDiscountPercent(sale: Sale): number {
  const integerMultiplier = 100;
  return Math.round(parseFloat(sale.discount.value) * integerMultiplier);
}
