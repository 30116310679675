import { ProductCard } from '@components/Product/ProductCard';
import { useEntitlements } from '@contexts/EntitlementsContext';
import { useProductTags } from '@contexts/ProductTagsContext';
import { longTextToProductIds } from '@lib/util/longTextToProductIds';
import { ToggleMoreItemsButton } from '@ui-components/components/SubCategorySelector/ToggleMoreItemsButton';
import classNames from 'classnames';
import { CaretDown, CaretUp } from 'phosphor-icons';
import { useState } from 'react';
import { getLocaleFromUrl } from 'utils';
import { mapToProductCardProps } from '../ProductRow/mapToProductCardProps';
import { usePackagesByIds } from '../ProductRow/usePackagesByIds';

type FeaturedProducts = {
  title: string;
  featuredProductIds: string;
  showMoreItemsLabel?: string;
  showLessItemsLabel?: string;
};

export const FeaturedProducts = ({
  title = 'Featured Products',
  featuredProductIds,
  showLessItemsLabel = 'Show less',
  showMoreItemsLabel = 'Show {{number}} more',
}: FeaturedProducts) => {
  const productIDs = longTextToProductIds(featuredProductIds);
  const { data: products, error, isLoading } = usePackagesByIds(productIDs);
  const MAX_INITIAL_DISPLAY = 4;
  const [showMoreItems, setShowMoreItems] = useState(false);
  const productTags = useProductTags();
  const { getUserEntitlement } = useEntitlements();
  const locale = getLocaleFromUrl();

  if (isLoading || error) {
    return null;
  }

  if (!products || products?.length === 0) {
    return null;
  }

  const formattedProducts = mapToProductCardProps(
    products,
    productTags,
    getUserEntitlement
  );

  const hasMoreItemsThanDisplayMax =
    formattedProducts.length > MAX_INITIAL_DISPLAY;
  const excessItems = formattedProducts.length - MAX_INITIAL_DISPLAY;

  const handleToggleShowMoreItems = () => {
    setShowMoreItems(!showMoreItems);
  };

  return (
    <div className="container relative mt-4 md:mt-8">
      <h2 className="header-small mb-3 lg:mb-2">{title}</h2>
      <div className="hide-scrollbar flex grid-cols-4 gap-4 overflow-x-scroll md:grid md:overflow-visible lg:gap-5 lg:gap-y-2">
        {formattedProducts.map((product, index) => (
          <ProductCard
            key={product.productId}
            className={classNames(
              'mr-0 min-w-[242px] md:min-w-full',
              index >= MAX_INITIAL_DISPLAY && !showMoreItems && 'md:hidden' // Add "hidden" class to items over the maxInitialDisplay on bigger screens
            )}
            locale={locale}
            {...product}
          />
        ))}
      </div>
      {hasMoreItemsThanDisplayMax && (
        <div
          className={classNames('mt-6 hidden justify-center md:flex')}
          data-test="product-row-toggle-more"
        >
          <ToggleMoreItemsButton
            excessItems={excessItems}
            onClick={handleToggleShowMoreItems}
            label={
              showMoreItems
                ? showLessItemsLabel
                : showMoreItemsLabel.replace(
                    '{{number}}',
                    excessItems.toString()
                  )
            }
            icon={showMoreItems ? CaretUp : CaretDown}
          />
        </div>
      )}
    </div>
  );
};
