import c from 'classnames';
export const ProductCardDiscountChip = ({
  percent,
  className = '',
}: {
  percent: number;
  className?: string;
}) => {
  return (
    <div
      data-test="product-card-discount"
      className={c(
        '-mr-2 rounded-l-sm bg-red-200 px-2 py-0.5 text-sm text-red-600 lg:mr-0 lg:rounded-sm xl:py-px xl:text-base',
        className
      )}
    >
      -{percent}%
    </div>
  );
};
