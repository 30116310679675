import { useCurrency } from '@contexts/CurrencyContext';
import { GetAssetByIdResponse } from '@lib/types/api/next-api/responses';
import useSWR from 'swr';

export const usePackagesByIds = (ids: string[]) => {
  const { currency } = useCurrency();
  // UseSWR to fetch data or return null if ids is empty.
  const { data, error, isLoading } = useSWR<GetAssetByIdResponse[]>(
    ids?.length > 0
      ? `/api/assets/get-multiple-assets?ids=${ids.join(',')}&currency=${currency}`
      : null
  );

  return {
    data,
    isLoading,
    error,
  };
};
