'use client';
import { TrackPromotionImpression } from '@components/TrackPromotionImpression/TrackPromotionImpression';
import { usePromoTrackingEvents } from '@components/TrackPromotionImpression/usePromoTrackingEvents';
import {
  LivestreamVideoFragment,
  MicrocopyFragment,
} from '@graphql/generated-contentful/graphql';
import { useCookieSettings } from '@lib/hooks/useCookieSettings';
import { formatContentfulMicrocopy } from '@lib/util/microcopy';
import { LiveStream } from '@ui-components/index';
import { Video } from '@ui-components/lib/types';
import { useState } from 'react';
import { interpolateStringWithData } from 'utils';
import { FeaturedProducts } from './FeaturedProducts';

type LivestreamVideoClientPlayerProps = {
  data: LivestreamVideoFragment;
  microcopy: MicrocopyFragment[];
  position: number;
};

/**
 * LivestreamVideoClientPlayer
 *
 * This component renders the player for a livestream video in a client side component.
 * It takes in the video data and microcopy passed from LivestreamVideo.tsx (server component),
 *
 * @param {LivestreamVideoClientPlayerProps} props - The component props containing the video data and microcopy
 * @returns A JSX element representing the livestream video player
 */
const LivestreamVideoClientPlayer = ({
  data,
  microcopy,
  position,
}: LivestreamVideoClientPlayerProps) => {
  const {
    titleInternal,
    title,
    trackAsPromo,
    description,
    videosCollection,
    startTime,
    endTime,
  } = data;

  const videos: Video[] =
    videosCollection?.items.map((item) => ({
      title: item?.title ?? '',
      url: item?.url ?? '',
      thumbnail: item?.image?.url ?? '',
      featuredProducts: item?.featuredProducts ?? '',
    })) ?? [];

  const [currentVideo, setCurrentVideo] = useState(videos[0]);

  const formattedMicrocopy = formatContentfulMicrocopy(microcopy || []);

  const computeLiveStatusLabel = () => {
    const currentDate = new Date();
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);

    if (currentDate > endDate) {
      return (
        formattedMicrocopy['livestream.previously-live'] || 'Previously live'
      );
    } else if (currentDate >= startDate && currentDate <= endDate) {
      return formattedMicrocopy['livestream.live'] || 'Live';
    } else {
      return formattedMicrocopy['livestream.live-soon'] || 'Live soon';
    }
  };

  const { functionalCategoryEnabled, targetingCategoryEnabled } =
    useCookieSettings();
  const cookieConsent = targetingCategoryEnabled && functionalCategoryEnabled;

  const { promoViewTrackingEvent } = usePromoTrackingEvents({
    title: titleInternal || '',
    typename: 'Livestream',
    position,
    trackAsPromo: trackAsPromo ?? false,
  });

  // This function updates the currentVideo state when the video is changed in LiveStream
  const handleVideoChange = (video: Video) => {
    setCurrentVideo(video);
  };

  return (
    <TrackPromotionImpression
      onImpression={promoViewTrackingEvent}
      shouldTrack={trackAsPromo ?? false}
    >
      <div className="container">
        <LiveStream
          title={title ?? ''}
          label={computeLiveStatusLabel()}
          description={description ?? ''}
          videoCookieOverlay={{
            description:
              formattedMicrocopy['livestream.cookie-overlay-description'],
            buttonLabel: formattedMicrocopy['livestream.cookie-overlay-button'],
          }}
          videos={videos}
          videoLibraryLabel={interpolateStringWithData(
            formattedMicrocopy['livestream.video-library-label'] ||
              '{{count}} videos',
            {
              count: videos.length.toString(),
            }
          )}
          cookieConsent={cookieConsent}
          playAriaLabel={formattedMicrocopy['livestream.play-aria-label']}
          onVideoChange={handleVideoChange}
        />
      </div>
      {currentVideo.featuredProducts && (
        <FeaturedProducts
          title={formattedMicrocopy['livestream.featured-products']}
          featuredProductIds={currentVideo.featuredProducts}
          showMoreItemsLabel={formattedMicrocopy['livestream.show-more']}
          showLessItemsLabel={formattedMicrocopy['livestream.show-less']}
        />
      )}
    </TrackPromotionImpression>
  );
};

export default LivestreamVideoClientPlayer;
