import { Duration } from 'date-fns';
import { formatDurationAsDays, interpolateStringWithData } from 'utils';

import { useMicrocopy } from '@contexts/MicrocopyContext';
import { MicrocopyFragment } from '@graphql/generated-contentful/graphql';
import { useCountdown } from '@lib/hooks/useCountdown';
import c from 'classnames';
import { Sale } from './types';

const NUMBER_OF_ZEROES = 2;

const zeroPad = (num: number) => String(num).padStart(NUMBER_OF_ZEROES, '0');

const formatCountdownDuration = (
  countdownDuration: Duration,
  locale: string
): string => {
  const hours = countdownDuration?.hours
    ? `${zeroPad(countdownDuration.hours)}:`
    : '';
  const minutes = countdownDuration?.minutes
    ? `${zeroPad(countdownDuration.minutes)}:`
    : '00:';
  const seconds = countdownDuration?.seconds
    ? zeroPad(countdownDuration.seconds)
    : `${minutes ? '00' : 0}`;
  let countdownFormatted = countdownDuration && `${hours}${minutes}${seconds}`;

  if (countdownDuration?.days && countdownDuration.days > 0) {
    countdownFormatted = formatDurationAsDays(countdownDuration, locale);
  }

  return countdownFormatted;
};

const useFormatCountdown = (
  isFlashDealActive: boolean,
  isFlashDealUpcoming: boolean,
  sale: Sale,
  locale: string
) => {
  let countdownDate: Date | undefined = undefined;
  if (isFlashDealActive) {
    countdownDate = sale.dates.ends;
  } else if (isFlashDealUpcoming) {
    countdownDate = sale.dates.starts;
  }

  const countdownDuration = useCountdown(countdownDate);
  return countdownDuration
    ? formatCountdownDuration(countdownDuration, locale)
    : '';
};

const getMicrocopyValues = (microcopy?: MicrocopyFragment[]) => {
  const upcomingFlashDealMicrocopy = microcopy?.find(
    (microcopyFragment) =>
      microcopyFragment.key === 'product-card.upcoming-flash-deal'
  )?.value;
  const activeFlashDealMicrocopy = microcopy?.find(
    (microcopyFragment) =>
      microcopyFragment.key === 'product-card.active-flash-deal'
  )?.value;

  return {
    upcomingFlashDealMicrocopy:
      upcomingFlashDealMicrocopy ?? 'Sale in {{time}}',
    activeFlashDealMicrocopy: activeFlashDealMicrocopy ?? '{{time}} left',
  };
};

type ProductCardSaleInfoProps = {
  sale: Sale;
  isFlashDealActive: boolean;
  isFlashDealUpcoming: boolean;
  locale: string;
};

export const ProductCardSaleInfo = ({
  sale,
  isFlashDealActive,
  isFlashDealUpcoming,
  locale,
}: ProductCardSaleInfoProps) => {
  const microcopy = useMicrocopy<{
    productCardMicrocopy: MicrocopyFragment[];
  }>();
  const productCardMicrocopy = microcopy?.productCardMicrocopy;
  const { upcomingFlashDealMicrocopy, activeFlashDealMicrocopy } =
    getMicrocopyValues(productCardMicrocopy);

  const countdownFormatted = useFormatCountdown(
    isFlashDealActive,
    isFlashDealUpcoming,
    sale,
    locale
  );

  return (
    (isFlashDealActive || isFlashDealUpcoming) && (
      <div
        data-test="product-card-flash-deal-info"
        className="flex items-center"
      >
        <div
          data-test={
            isFlashDealActive
              ? 'active-flash-deal-stage'
              : 'upcoming-flash-deal-stage'
          }
          className={c('tag-regular whitespace-nowrap text-nowrap', {
            'rounded bg-grey-100 px-1 text-green-500': isFlashDealUpcoming,
            'text-red-600': isFlashDealActive,
          })}
        >
          {interpolateStringWithData(
            isFlashDealActive
              ? activeFlashDealMicrocopy
              : upcomingFlashDealMicrocopy,
            { time: countdownFormatted }
          )}
        </div>
      </div>
    )
  );
};
