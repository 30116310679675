'use client';

import { ServerTimeContext } from '@contexts/ServerTimeContext';
import { isWithinInterval } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { getAdjustedClientTime } from 'utils';
import { Sale } from './types';
import { useFlashDeal } from './useFlashDeal';

/**
 * Returns true if there is a currently active sale of any type.
 * Sets timeouts so that if the sale start or end date is hit, the UI updates accordingly with the new sale status.
 */
export const useIsActiveSale = (sale?: Sale) => {
  const { serverTimeOffsetInMs } = useContext(ServerTimeContext);

  const [isSaleActive, setIsSaleActive] = useState<boolean>(
    sale?.dates
      ? isWithinInterval(
          getAdjustedClientTime(new Date(), serverTimeOffsetInMs),
          {
            start: sale.dates.starts,
            end: sale.dates.ends,
          }
        )
      : false
  );

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (sale) {
      const adjustedNow = getAdjustedClientTime(
        new Date(),
        serverTimeOffsetInMs
      );
      const isSaleUpcoming = adjustedNow < sale.dates.starts;
      const isSaleActiveNow = isWithinInterval(adjustedNow, {
        start: sale.dates.starts,
        end: sale.dates.ends,
      });
      const MAX_TIMER = 172800000; // 2 days

      if (isSaleUpcoming) {
        // if sale is upcoming, set timeout so isActiveSale will get updated when sale starts
        const timeUntilSaleStarts =
          sale.dates.starts.getTime() - adjustedNow.getTime();

        if (timeUntilSaleStarts < MAX_TIMER) {
          // don't bother setting a timeout if sale starts in longer than someone would leave a page open
          timeout = setTimeout(() => {
            setIsSaleActive(true);
          }, timeUntilSaleStarts);
        }
      } else if (isSaleActiveNow) {
        // if sale active, set timeout so isActiveSale will get updated when sale ends
        const timeUntilSaleEnds =
          sale.dates.ends.getTime() - adjustedNow.getTime();

        if (timeUntilSaleEnds < MAX_TIMER) {
          // don't bother setting a timeout if sale ends in longer than someone would leave a page open
          timeout = setTimeout(() => {
            setIsSaleActive(false);
          }, timeUntilSaleEnds);
        }
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [sale, serverTimeOffsetInMs]);

  return isSaleActive;
};

export const useSaleState = (sale?: Sale) => {
  const { isFlashDealUpcoming } = useFlashDeal(sale);
  const isSaleActive = useIsActiveSale(sale);

  return {
    active: isSaleActive,
    upcoming: isFlashDealUpcoming,
    start: sale?.dates.starts,
    end: sale?.dates.ends,
    type: sale?.type,
  };
};
