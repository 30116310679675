import { ServerTimeContext } from '@contexts/ServerTimeContext';
import { SaleType } from '@lib/types/Sale';
import { isWithinInterval } from 'date-fns';
import { useContext } from 'react';
import { getAdjustedClientTime } from 'utils';
import { Sale } from './types';

/**
 * Determines if a flash deal is upcoming or currently active
 */
export const useFlashDeal = (sale?: Sale) => {
  const { serverTimeOffsetInMs } = useContext(ServerTimeContext);
  const adjustedNow = getAdjustedClientTime(new Date(), serverTimeOffsetInMs);
  const hasFlashDeal = sale && sale.type === SaleType.FlashDealTimeBased;
  const isFlashDealActive = Boolean(
    hasFlashDeal &&
      isWithinInterval(adjustedNow, {
        start: sale.dates.starts,
        end: sale.dates.ends,
      })
  );
  const isFlashDealUpcoming = Boolean(
    hasFlashDeal && adjustedNow < sale.dates.starts
  );

  return { isFlashDealActive, isFlashDealUpcoming };
};
