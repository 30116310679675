import { ProductRowHeading } from '@components/Product/ProductRowHeading';
import { useResults } from '@components/Search/hooks/useResults';
import { AtomicListingContext } from '@contexts/coveo/AtomicListingContext';
import { Scalars } from '@graphql/generated-contentful/graphql';
import { Maybe } from '@lib/types/Maybe';
import { useContext } from 'react';

export const DynamicProductListingHeading = ({
  title,
  description,
  text,
  link,
  onLinkClick,
}: {
  title?: Maybe<string>;
  description?: Scalars['JSON']['output'];
  text?: Scalars['JSON']['output'];
  link?: { href: string; label: string };
  onLinkClick?: (label: string) => void;
}) => {
  const { searchEngine } = useContext(AtomicListingContext);
  const { results: resultState } = useResults(searchEngine);

  if (!resultState?.results || resultState?.results.length === 0) {
    return null;
  }

  return (
    <ProductRowHeading
      title={title}
      description={description}
      text={text}
      link={link}
      onLinkClick={onLinkClick}
    />
  );
};
