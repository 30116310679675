'use client';

import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { AccordionFragment } from '@graphql/generated-contentful/graphql';
import { analytics } from '@lib/analytics/page-events';
import { isDefined } from '@lib/util/isDefined';

import { Accordion, AccordionItem } from 'ui-components';
import { BlocksTitle } from '../shared/BlocksTitle';

type AccordionBlockProps = {
  data: AccordionFragment;
};

const AccordionBlock = ({ data }: AccordionBlockProps) => {
  const accordionItems = data?.sectionsCollection?.items
    ?.map((item) => {
      return {
        title: item?.heading,
        content: documentToHtmlString(item?.content?.json),
      };
    })
    .filter(
      (item): item is AccordionItem =>
        isDefined(item.title) && isDefined(item.content)
    );

  if (!accordionItems) {
    return null;
  }

  return (
    <div className="main-grid">
      <div className="col-span-4 md:col-span-8 md:col-start-3 md:col-end-11">
        {(data.accordionTitle || data.accordionDescription || data.text) && (
          <div className="mb-8 md:text-center">
            <BlocksTitle
              title={data.accordionTitle}
              description={data.accordionDescription}
              text={data.text}
            />
          </div>
        )}
        <Accordion
          headingLevel="h3"
          items={accordionItems}
          onAccordionAction={(heading, action) => {
            analytics.pushAccordionClickEvent({
              faq_action_name: heading,
              faq_action: action,
            });
          }}
        />
      </div>
    </div>
  );
};

export default AccordionBlock;
