'use client';

import { ProductCard } from '@components/Product/ProductCard';
import { useCurrency } from '@contexts/CurrencyContext';
import { useEntitlements } from '@contexts/EntitlementsContext';
import { useRouter } from '@contexts/Router/RouterContext';
import { AtomicContext } from '@contexts/coveo/AtomicContext';
import { Result, buildInteractiveResult } from '@coveo/atomic-react';
import {
  getActiveSale,
  getDiscountPercent,
  getSalePrice,
} from '@lib/coveo/sale';
import { Sale, SaleType } from '@lib/types/Sale';
import { Tag } from '@lib/types/common/Tag';
import { getTagsDetailsBySlugs } from '@lib/util/tags';
import * as Sentry from '@sentry/nextjs';
import c from 'classnames';
import { useContext } from 'react';

type Props = {
  result: Result;
  allProductTags?: Tag[];
  context: AtomicContext;
  className?: string;
};

export const HeadlessProductCardWrapper = ({
  result,
  allProductTags = [],
  context,
  className,
}: Props) => {
  const { searchEngine } = useContext(context);
  const { locale } = useRouter();
  const { currency } = useCurrency();
  const { getUserEntitlement } = useEntitlements();

  if (!searchEngine) {
    return null;
  }

  const interactiveResultController = buildInteractiveResult(searchEngine, {
    options: { result },
  });

  const {
    ec_name,
    ec_product_id,
    ec_thumbnails,
    publisher_id,
    publisher_name,
    ec_rating,
    ec_rating_count,
    ec_price,
    ec_sale,
    ec_tags,
    ec_flash_teaser_starts_at,
  } = result.raw;
  let sales: Sale[] = [];
  try {
    sales = ec_sale ? (JSON.parse(ec_sale as string) as Sale[]) : [];
  } catch (e) {
    Sentry.captureException(e);
  }
  const sale = getActiveSale(sales, ec_flash_teaser_starts_at as string);

  const tag = ec_tags
    ? getTagsDetailsBySlugs(ec_tags as string[], allProductTags)[0]
    : null;
  // .toString() needed to make sure id is a string and not number (for comparison with entitlement set)
  const productId = ec_product_id
    ? JSON.parse(ec_product_id as string).toString()
    : '';
  const entitlement = getUserEntitlement(productId);

  return (
    <ProductCard
      name={ec_name as string}
      productId={productId}
      imageUrl={ec_thumbnails as string}
      publisher={{
        name: publisher_name as string,
        id: publisher_id as string,
      }}
      rating={ec_rating as number}
      ratingCount={ec_rating_count as number}
      price={{
        currentPrice: sale
          ? getSalePrice(sale, currency)
          : (ec_price as number),
        originalPrice: ec_price as number,
        currency,
        discountPercent: sale?.discount.value
          ? getDiscountPercent(sale)
          : undefined,
      }}
      sale={
        sale
          ? {
              type: sale.type ?? SaleType.Sale,
              dates: {
                starts: new Date(sale.dates.starts),
                ends: new Date(sale.dates.ends),
              },
            }
          : undefined
      }
      label={tag ? { text: tag.label, iconName: tag.iconName } : undefined}
      entitlement={entitlement}
      locale={locale}
      // https://docs.coveo.com/en/headless/latest/reference/search/controllers/interactive-result/#select
      extraEventHandlers={{
        onClick: () => interactiveResultController.select(),
        onContextMenu: () => interactiveResultController.select(),
        onMouseUp: () => interactiveResultController.select(),
        onMouseDown: () => interactiveResultController.select(),
        onTouchStart: () => interactiveResultController.beginDelayedSelect(),
        onTouchEnd: () => interactiveResultController.cancelPendingSelect(),
      }}
      className={c('lg:-mx-2', className)}
    />
  );
};
